<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("menu.modules") }}
        </router-link>
        <router-link to="/module/teacher/branch" class="btn btn-primary align-self-center ms-3">
            {{ $t("pages.module.teacher.branch.title") }}
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr024.svg" />
            </span>
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.teacher.title')"
        :subTitle="$t('pages.module.teacher.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:name="{ row: record }">
            {{ record.firstname + ' ' + record.lastname }}
        </template>
        <template v-slot:identificationNumber="{ row: record }">
            {{ record.identificationNumber ?? "-" }}
        </template>
        <template v-slot:mobilePhone="{ row: record }">
            <a :href="'tel:' + record.mobile_phone" class="text-gray-600 text-hover-primary mb-1" v-if="record.mobile_phone">
                {{ record.mobile_phone }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:email="{ row: record }">
            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1" v-if="record.email">
                {{ record.email }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchTeacher(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-tooltip :content="$t('pages.module.teacher.trailer.title')" placement="top">
                    <router-link :to="'/module/teacher/' + record.id + '/trailer'" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/social/soc007.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_teacher" ref="addTeacherModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form" ref="teacherForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.branch') }}</label>
                                <el-form-item prop="data.branch_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.branch_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(branch, branchIndex) in branches" :key="branchIndex" :value="branch.id" :label="branch.name">{{ branch.name }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.firstname') }}</label>
                                <el-form-item prop="data.firstname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.firstname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.lastname') }}</label>
                                <el-form-item prop="data.lastname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.lastname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.identificationNumber') }}</label>
                                <el-form-item prop="data.identification_number">
                                    <el-input v-model="form.data.identification_number" v-mask="{ mask:'99999999999', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.birthDate') }}</label>
                                <el-form-item prop="data.birth_date">
                                    <el-date-picker v-model="form.data.birth_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledDate"></el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.email') }}</label>
                                <el-form-item prop="data.email" :rules="$validation.getMessage(['email'])">
                                    <el-input v-model="form.data.email" autocomplete="off"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.mobilePhone') }}</label>
                                <el-form-item prop="data.mobile_phone">
                                    <el-input v-model="form.data.mobile_phone" v-mask="{ mask:'+999999999999', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.address') }}</label>
                                <el-form-item prop="data.address">
                                    <el-input v-model="form.data.address" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-1">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.description') }}</label>
                                <el-form-item prop="translate.description">
                                    <el-input v-model="form.translate.description" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.sort') }}</label>
                                <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.status') }}</label>
                                <el-form-item prop="data.status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.teacher.cols.image') }}</label>
                                <el-form-item prop="data.image_id">
                                    <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage" list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                        <i class="bi bi-plus" />
                                    </el-upload>
                                    <el-dialog v-model="image.dialogVisible" width="30%">
                                        <img style="width: 100%" :src="image.dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.module.teacher.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.teacher.cols.name"),
                    scopedSlots: {customRender: "name"}
                },
                {
                    name: this.$t("pages.module.teacher.cols.branch"),
                    key: "branch.name"
                },
                {
                    name: this.$t("pages.module.teacher.cols.identificationNumber"),
                    scopedSlots: {customRender: "identificationNumber"}
                },
                {
                    name: this.$t("pages.module.teacher.cols.email"),
                    scopedSlots: {customRender: "email"}
                },
                {
                    name: this.$t("pages.module.teacher.cols.mobilePhone"),
                    scopedSlots: {customRender: "mobilePhone"}
                },
                {
                    name: this.$t("pages.module.teacher.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.module.teacher.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                translate: {},
                data: {}
            },
            selectedRowKeys: [],
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.module.teacher.table;
        },
        branches() {
            return this.$store.state.module.teacher.branch.table.data;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.teacher.title"), [this.$t("menu.moduleManagement")]);
        this.$store.dispatch('module/teacher/get', {page: {}});
        this.$store.dispatch('module/teacher/branch/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        newTeacher(){
            this.form.updateStatus = false;
            this.resetImageData();
            this.form.translate = {
                language_id: this.$root.defaultLanguage.id
            };
            this.form.data = {
                sort: 1,
                active: true
            };
            this.form.title = this.$t("pages.module.teacher.newTeacher");
            this.showModal(this.$refs.addTeacherModal);
        },
        fetchTeacher(record) {
            this.resetImageData();
            this.form.updateStatus = true;
            this.form.title =  this.$t("pages.module.teacher.editTeacher")
            this.axios.get(this.endpoints['module_teacher']+ '/' + record.id).then(response => {
                let data = response.data.data;

                let translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                this.form.translate = translate;
                this.form.data = data;

                if(data.image){
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }

                this.showModal(this.$refs.addTeacherModal);
            });
        },
        onSubmit(){
            this.$refs.teacherForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.image.uploadList.length > 0){
                        this.submitFormWithUpload();
                    } else {
                        if(!(this.image.fileList.length > 0)){
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload(){
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm(){
            if(this.prepareTeacherFormData()) {
                if(this.form.data.id) {
                    this.axios.put(this.endpoints['module_teacher'] + '/' + this.form.data.id, this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.addTeacherModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                }else {
                    this.axios.post(this.endpoints['module_teacher'], this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.addTeacherModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                }
            }
        },
        prepareTeacherFormData(){
            let formData = this.form.data;

            this.form.data = {...this.form.translate, ...formData};

            return true;
        },
        deleteRecord(id){
            this.$store.dispatch("module/teacher/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newTeacher();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/teacher/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/teacher/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        resetImageData(){
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList){
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        },
        disabledDate(date){
            return date.getTime() > Date.now()
        },
    }
}
</script>

<style></style>